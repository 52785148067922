import React, { Component } from "react";
import PropTypes from "prop-types";

class CartInput extends Component {
  render() {
    return (
      <div className="form-group">
        <input
          type="text"
          name={this.props.name}
          placeholder={this.props.placeholder}
          defaultValue={this.props.address[this.props.name]}
          onBlur={event =>
            this.props.setAddress(this.props.name, event.target.value)
          }
        />
      </div>
    );
  }
}

CartInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  setAddress: PropTypes.func
};

CartInput.defaultProps = {
  className: "",
  name: "<NAME>",
  placeholder: "<PLACEHOLDER>",
  setAddress: (field, value) => {
    console.log(field, value);
  }
};

export default CartInput;
