import React from "react";
import Popup from "reactjs-popup";
import BurgerIcon from "./BurgerIcon";
import Menu from "./Menu";

const contentStyle = {
  background: "transparent",
  width: "80%",
  border: "none"
};

const Hamburger = () => (
  <div className="burger-menu-wrapper openingTimes-Link">
    <Popup
      modal
      contentStyle={contentStyle}
      closeOnDocumentClick={false}
      trigger={open => <BurgerIcon open={open} />}
    >
      {close => <Menu close={close} />}
    </Popup>
  </div>
);

export default Hamburger;
