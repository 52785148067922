import React from "react";
import PropTypes from "prop-types";

const SplitButton = props => (
  <a
    className={`splitButton ${
      props.button === props.activeItem ? "active" : ""
    }`}
    href={`#${props.button}`}
    onClick={e => {
      e.preventDefault();
      props.setActiveItem(props.button);
    }}
    key={`splitButton_${props.button}`}
  >
    {props.button}
  </a>
);

SplitButton.propTypes = {
  button: PropTypes.string,
  setActiveItem: PropTypes.func
};

export default SplitButton;
