import React from "react";
import Hamburger from "./Hamburger";
import logo from "../logo.svg";
import { Link } from "react-router-dom";
import ic_bag from "../icons/ic_bag.svg";
import Fade from "react-reveal/Fade";
import Tada from "react-reveal/Tada";

const Header = props => (
  <div className={"header"}>
    <header className={"desktop"}>
      <nav>
        <ul>
          <li>
            <Link to="/">Über Uns</Link>
          </li>
          <li>
            <Link to="/zimmer">Zimmer</Link>
          </li>
          <li className={"logo"}>
            <Fade top duration={2000} distance={`150px`}>
              <Link to="/">
                <img
                  src={logo}
                  className={"logo"}
                  alt="Logo von Weingut Wicht"
                />
              </Link>
            </Fade>
          </li>
          <li>
            <Link to="/webshop">Webshop</Link>
          </li>
          <li>
            <Tada spy={props.items}>
              <Link to="/warenkorb" className={`cartButton`}>
                <img src={ic_bag} alt="Zum Warenkorb" />
                <div className="items">{props.items}</div>
              </Link>
            </Tada>
          </li>
        </ul>
      </nav>
    </header>
    <header className="mobile">
      <Hamburger />
      <Link to={"/"} className="logo">
        <img src={logo} alt="Weingut Wicht" className={"logo"} />
      </Link>
      <Link to="/warenkorb" className={`cart`}>
        <Tada spy={props.items}>
          <img src={ic_bag} alt="Zum Warenkorb" />
        </Tada>
        <div className="items">{props.items}</div>
      </Link>
    </header>
  </div>
);

export default Header;
