import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "./Image";
import NumericInput from "react-numeric-input";

class CartProduct extends Component {
  render() {
    const price = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR"
    }).format(this.props.price * this.props.amount);

    return (
      <section className="cartProduct">
        <div className="image">
          <Image src={this.props.img} width={128} />
        </div>
        <div className="text form">
          <h3>{this.props.name}</h3>
          <div className="stepper">
            <NumericInput
              mobile
              value={this.props.amount}
              onChange={number =>
                this.props.setAmountInCart(this.props.name, number)
              }
              min={0}
              max={50}
            />
            <input type="button" readOnly value={`${price}`} />
          </div>
        </div>
      </section>
    );
  }
}

CartProduct.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.string,
  price: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  src: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string
};

CartProduct.defaultProps = {
  name: "<NAME>",
  description: "",
  price: 5
};

export default CartProduct;
