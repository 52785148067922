import "./App.scss";
import "react-dates/initialize";

import { AnimatedSwitch, spring } from "react-router-transition";
import { Map, fromJS } from "immutable";
import React, { Component } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";

import AGB from "./pages/AGB";
import Cart from "./pages/Cart";
import Datenschutz from "./pages/Datenschutz";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/Home";
import ScrollToTop from "./components/ScrollToTop";
import { Toaster } from 'react-hot-toast';
import Webshop from "./pages/Webshop";
import Zimmer from "./pages/Zimmer";

// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`
  };
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22
  });
}

// child matches will...
const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    scale: 1.2
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8)
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
    scale: bounce(1)
  }
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shop: fromJS({
        1: {
          name: "Welschriesling",
          category: "Wein",
          img: "shop/Welschriesling.jpg",
          description:
            "Mit seiner leicht spritzigen Fruchtigkeit und einem süffigen, säurebetonten Geschmack, gilt er als wahrer Klassiker unter den steirischen  Weinen. Unser Welsch ist trocken ausgebaut mit einprägsamer Apfelnote.",
          meta: "Qualitätswein, trocken, enthält Sulfite, Alc. 12% vol. 0,75l",
          price: 6
        },
        2: {
          name: "Weißburgunder",
          category: "Wein",
          img: "shop/Weissburgunder.jpg",
          description:
            "Der Weißburgunder gilt als besonders gehaltvoller und harmonischer Wein. Wegen seiner vornehmlich dezenten Art gilt er für uns Österreicher als der universelle Speisebegleiter schlechthin.",
          meta: "Qualitätswein, trocken, enthält Sulfite, Alc. 12% vol. 0,75l",
          price: 6.5
        },
        3: {
          name: "Rivaner",
          category: "Wein",
          img: "shop/Rivaner.jpg",
          description:
            "Vulgo Müller-Thurgau gennant, gilt der Rivaner als frühreife Sorte, welche einen milden Wein liefert. Unser Rivaner ist ein äußerst runder Wein mit leicht muskatigem Bukett.",
          meta: "Landwein, trocken, enthält Sulfite, Alc. 12% vol. 0,75l",
          price: 6
        },
        4: {
          name: "Cuvée",
          category: "Wein",
          img: "shop/Cuvee.jpg",
          description:
            "Erst wenn jede reine Weinsorte ausgereift ist wird unsere weißer Cuvée zu einer harmonisch ausgewogenen Komposition zusammengestellt. Besonders wichtig ist es hierbei die richtige Balance zwischen den einzelnen Komponenten zu finden.",
          meta: "Landwein, trocken, enthält Sulfite, Alc. 12% vol. 0,75l",
          price: 6
        },
        5: {
          name: "Welschriesling Steirerland",
          category: "Wein",
          img: "shop/Landwein.jpg",
          description:
            "Unser Landwein ist charakteristisch frisch und leicht zu trinken und somit die beste Wahl für euren Spritzer.",
          meta: "Landwein, trocken, enthält Sulfite, Alc. 12% vol. 1l",
          price: 3.5
        },
        6: {
          name: "Heckenklescher",
          category: "Wein",
          img: "shop/Heckenklescher.jpg",
          description:
            "Nach frischen Beeren duftend ist mit säuerlichem Reiz, bildet unser Heckenklescher den perfekten Wein für laue Sommerabende. ",
          meta: "Rosé, trocken, enthält Sulfite, Alc. 11% vol. 0,75l",
          price: 6
        },
        7: {
          name: "Schilcher Rosé",
          category: "Wein",
          img: "shop/SchilcherRose.jpg",
          description:
            "Leuchtend in seiner Farbe, mit herrlich fruchtigem Bukett. – Ein charmanter Begleiter.",
          meta: "Landwein, trocken, enthält Sulfite, Alc. 10% vol. 0,75l",
          price: 6
        },
        8: {
          name: "Blauer Zweigelt",
          category: "Wein",
          img: "shop/Zweigelt.jpg",
          description:
            "",
          meta: "",
          price: 6
        },
        9: {
          name: "Schilcher Perle",
          category: "Schaumwein",
          img: "shop/Sekt.jpg",
          description:
            "Aromatisch vielfältig, feinperliger Schaumwein. – Eine fruchtige Erfrischung.",
          meta: "",
          price: 8
        },
        10: {
          name: "Rosecco",
          category: "Schaumwein",
          img: "shop/Sekt.jpg",
          description:
            "Durch die perfekte Mischung aus Säure und eleganter Restsüße bildet dieser Wein einen geschmackvollen Trinkgenuss.",
          meta:
            "Österreichischer Schaumwein, trocken, enthält Sulfite, Alc. 10% vol.\n" +
            "0,75l",
          price: 8
        },
        11: {
          name: "Holunder",
          category: "Schnaps",
          img: "shop/Holunder.jpg",
          description: "Klarer Holunder Brand mit fruchtigem Abgang.",
          meta: "Alc. 40% vol. 0,5l",
          price: 25
        },
        12: {
          name: "Kirschenbrand",
          category: "Schnaps",
          img: "shop/Apfel.jpg",
          description:
            "Eine Mischung aus den dunklen, saftigen Herzkirschen und der Vogelkirsche machen unseren Kirschenbrand zu einem wahren Erlebnis.",
          meta: "Alc. 40% vol. 0,5l",
          price: 15
        },
        13: {
          name: "Apfel",
          category: "Schnaps",
          img: "shop/Apfel.jpg",
          description:
            "Fruchtig wild, aber dennoch herrlich mild ist unser Apfelbrand durch eine Auswahl verschiedener frisch ausgereifter Äpfel der alten Sorte.",
          meta: "Alc. 40% vol. 0,5l",
          price: 13
        },
        14: {
          name: "Kriecherl",
          category: "Schnaps",
          img: "shop/Kriecherl.jpg",
          description:
            "Ein seltener Brand, da die Ernte dieser Frucht nur alle zwei Jahre erfolgen kann. Unser Kriecherl präsentiert sich harmonisch, dominant in seinem Fruchtaroma.",
          meta: "Alc. 40% vol. 0,5l",
          price: 13
        },
        15: {
          name: "Dirndlbeer",
          category: "Schnaps",
          img: "shop/Kriecherl.jpg",
          description:
            "Die Dirndl ist eine eine Kornelkirsche, welche diesen Brand mit ihrer fruchtig-süßen Noten perfekt am Gaumen wiedergibt.",
          meta: "Alc. 40% vol. 0,5l",
          price: 15
        },
        16: {
          name: "Zwetschke",
          category: "Schnaps",
          img: "shop/Zwetschke.jpg",
          description:
            "Unsere Zwetschke besitzt einen langen, fruchtbetonten Geschmack mit einem aromatischen Bukett.",
          meta: "Alc. 40% vol. 0,5l",
          price: 13
        },
        17: {
          name: "Birne",
          category: "Schnaps",
          img: "shop/Birne.jpg",
          description:
            "Feingliedrig und weich in seinem Geschmack ist unsere Birne.",
          meta: "Alc. 40% vol. 0,5l",
          price: 13
        },
        18: {
          name: "Traubenbrand",
          category: "Schnaps",
          img: "shop/Traube.jpg",
          description:
            "Die Süße erhält unser Traubenbrand durch die Verwendung von Esstrauben, zur Vollendung im Abgang werden diese mit der bekannten Welschtraube gemischt somit profitiert diese Brand",
          meta: "Alc. 40% vol. 0,5l",
          price: 13
        },
        19: {
          name: "Nuss Likör",
          category: "Schnaps",
          img: "shop/Nuss.jpg",
          description: "",
          meta: "Alc. 38% vol. 0,5l",
          price: 13
        },
        20: {
          name: "Quitte Likör",
          category: "Schnaps",
          img: "shop/Quitte.jpg",
          description: "",
          meta: "Alc. 38% vol. 0,5l",
          price: 15
        },
        21: {
          name: "Marille",
          category: "Schnaps",
          img: "shop/Marille.jpg",
          description: "",
          meta: "Alc. 40% vol. 0,5l",
          price: 15
        },
        22: {
          name: "Mispel Brand",
          category: "Schnaps",
          img: "shop/Mispel.jpg",
          description: "",
          meta: "",
          price: 15
        },
        23: {
          name: "Pfirsichbrand",
          category: "Schnaps",
          img: "shop/Pfirsichbrand.jpg",
          description: "",
          meta: "Alc. 40% vol. 0,5l",
          price: 15
        },
        24: {
          name: "Apfelsaft",
          category: "Alkoholfrei",
          img: "shop/apfelsaft.jpg",
          description: "Steirischer Apfelsaft",
          meta: "100% Fruchtsaft 1l",
          price: 3
        },
        25: {
          name: "Roter Traubensaft",
          category: "Alkoholfrei",
          img: "shop/saftrot.jpg",
          description: "Roter Traubensaft",
          meta: "100% Fruchtsaft 1l",
          price: 3.5
        },
        26: {
          name: "Weißer Traubensaft",
          category: "Alkoholfrei",
          img: "shop/saft2.jpg",
          description: "Weisser Traubensaft",
          meta: "100% Fruchtsaft 1l",
          price: 3
        },
        27: {
          name: "Rotweinessig",
          category: "Alkoholfrei",
          img: "shop/Rotweinessig.jpg",
          description: "Perfekt für steirische Salate ",
          meta: "6% Säure 0,5l",
          price: 5
        }
      }),
      cart: Map(),
      address: fromJS({
        name: "",
        company: "",
        address: "",
        zip: "",
        city: "",
        UID: "",
        country: "Österreich",
        email: "",
        phone: ""
      }),
      shopSent: false,
    };
  }

  addToCart(product, amountToAdd) {
    let amount = amountToAdd;

    if (this.state.cart.getIn([product.name, "amount"])) {
      amount += this.state.cart.getIn([product.name, "amount"]);
    }

    this.setState({
      cart: this.state.cart.setIn([product.name], {
        ...product,
        amount
      })
    });
  }

  setAmountInCart(product, amount) {
    this.setState({
      cart: this.state.cart.setIn([product, "amount"], amount)
    });
  }

  setAddress(field, value) {
    this.setState({
      address: this.state.address.setIn([field], value)
    });
  }

  getTotalValueInCart() {
    let amount = 0;

    this.state.cart.forEach(item => {
      amount += item.amount * item.price;
    });

    return amount;
  }

  getAmountOfItemsInCart() {
    let amount = 0;

    this.state.cart.forEach(item => {
      amount += item.amount;
    });

    return amount;
  }

  clearCart() {
    this.setState({
      cart: Map(),
      shopSent: true,
    });
  }

  render() {
    return (
      <Router
        onUpdate={() => {
          window.scrollTo(0, 0);
          console.log("router");
        }}
      >
        <ScrollToTop>
          <Header
            items={this.getAmountOfItemsInCart()}
            sum={this.getTotalValueInCart()}
          />
          <Toaster
            position="bottom-center"
            reverseOrder={true}
          />
          <section className="body">
            <AnimatedSwitch
              atEnter={bounceTransition.atEnter}
              atLeave={bounceTransition.atLeave}
              atActive={bounceTransition.atActive}
              mapStyles={mapStyles}
              className="route-wrapper"
            >
              <Route
                path="/"
                exact
                component={() => <Home data={{ ...this.state }} />}
              />
              <Route
                path="/zimmer"
                exact
                component={() => <Zimmer data={{ ...this.state }} />}
              />
              <Route
                path="/kontakt"
                exact
                component={() => <Home data={{ ...this.state }} />}
              />
              <Route
                path="/webshop"
                exact
                component={() => (
                  <Webshop
                    data={{ ...this.state }}
                    addToCart={(product, amount) =>
                      this.addToCart(product, amount)
                    }
                  />
                )}
              />
              <Route
                path="/warenkorb"
                exact
                component={() => (
                  <Cart
                    data={{ ...this.state }}
                    addToCart={(product, amount) =>
                      this.addToCart(product, amount)
                    }
                    setAmountInCart={(product, amount) =>
                      this.setAmountInCart(product, amount)
                    }
                    setAddress={(field, value) => this.setAddress(field, value)}
                    clearCart={() => this.clearCart()}
                    items={this.getAmountOfItemsInCart()}
                    sum={this.getTotalValueInCart()}
                    cart={this.state.cart}
                    shopSent={this.state.shopSent}
                    address={this.state.address}
                  />
                )}
              />
              <Route
                path="/datenschutz"
                exact
                component={() => <Datenschutz data={{ ...this.state }} />}
              />
              <Route
                  path="/agb"
                  exact
                  component={() => <AGB data={{ ...this.state }} />}
              />
            </AnimatedSwitch>
          </section>
          <Footer />
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
