import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import Image from "../components/Image";

class Home extends Component {
  render() {
    const duration = window.innerWidth < 400 ? 1000 : 3000;
    const distance = window.innerWidth < 400 ? `30px` : `100px`;
    return (
      <div>
        <section className="content hero">
          <div className="image">
            <Fade bottom duration={duration} distance={distance}>
              <Image src={"IMG_1185.jpg"} />
            </Fade>
          </div>
          <div className="text has-text-centered">
            <Fade bottom duration={duration} distance={distance}>
              <h1>
                Herzlich Willkommen bei den Wicht’s. <br />
                Bei uns fühlt sich jeder pudelwohl.
              </h1>
            </Fade>
          </div>
        </section>
        <section className="content yellow">
          <div className="text has-text-centered">
            <Fade bottom duration={duration} distance={distance}>
              <p>
                &gt;&gt;Was man liebt, macht man gerne.&lt;&lt;
                <br />
                Und wir Wicht’s lieben was wir tun: Wein <br />
                machen und Gäste in unserem zu Hause <br />
                willkommen heißen.
              </p>
            </Fade>
          </div>
          <div className="image">
            <Fade bottom duration={duration} distance={distance}>
              <Image src={"start_sekt.jpg"} />
            </Fade>
          </div>
        </section>
        <section className="content">
          <div className="image">
            <Fade bottom duration={duration} distance={distance}>
              <Image src={"IMG_1136.jpg"} />
            </Fade>
          </div>
          <div className="text has-text-centered">
            <Fade bottom duration={duration} distance={distance}>
              <p>
                Gemütlich und familiär, euer Wohl liegt uns am Herzen. – Ob
                klein, ob groß, ob mit oder ohne Haar’ in unseren Zimmern ist
                jeder erwünscht. Euer Aufenthalt bei uns Wicht’s ist
                unkompliziert und entspannt.
              </p>
            </Fade>
          </div>
        </section>
        <section className="content">
          <div className="text has-text-centered">
            <Fade bottom duration={duration} distance={distance}>
              <p>
                <b>&gt;&gt;Wir Wicht’s&lt;&lt;</b>
                <br />
                Wir, sind genau genommen Christa, Franz und Cindy. Und unser
                Interesse am Wein hat uns vor rund 19 Jahren zu den Weinbauern
                gemacht die wir heute sind. Gemeinsam und mit der vorhandenen
                Erfahrung von Christa lernten wir stets aus unseren Fehlern und
                wurden somit zu wahren Profis im Weinbau
              </p>
            </Fade>
          </div>
          <div className="image">
            <Fade bottom duration={duration} distance={distance}>
              <Image src={"_DSC6464.jpg"} />
            </Fade>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
