import React from "react";
import PropTypes from "prop-types";

const Image = props => {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? "http://weingut-wicht.at.test/img/"
      : "/img/";

  const w = `&w=${props.width}`;

  const h = props.height > 0 ? `&h=${props.height}&fit=crop` : ``;

  return (
    <picture className={props.className}>
      <source
        srcSet={`${baseUrl}${
          props.src
        }?fm=webp${w}${h}&dpr=2&q=50 2x, ${baseUrl}${
          props.src
        }?fm=webp${w}${h} 1x`}
        type="image/webp"
      />
      <source
        srcSet={`${baseUrl}${
          props.src
        }?fm=pjpg${w}${h}&dpr=2&q=50 2x, ${baseUrl}${
          props.src
        }?fm=pjpg${w}${h} 1x`}
        type="image/jpeg"
      />

      <img
        src={`${baseUrl}${props.src}`}
        alt={props.alt}
        title={props.title ? props.title : props.alt}
      />
    </picture>
  );
};

Image.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  src: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string
};

Image.defaultProps = {
  className: "",
  width: 720,
  height: 0
};

export default Image;
