import React from "react";

export default ({ open, ...props }) => (
  <div className="burger-menu-wrapper" {...props}>
    Menü
    <div
      className={
        open
          ? "burger-menu open openingTimes-Link"
          : "burger-menu openingTimes-Link"
      }
    >
      <div className="bar1" key="b1" />
      <div className="bar2" key="b2" />
      <div className="bar3" key="b3" />
    </div>
  </div>
);
